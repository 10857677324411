import { BookIcon } from "@biblioteksentralen/icons";
import { Box, BoxProps, Grid, ResponsiveValue } from "@biblioteksentralen/react";
import { isPublicationWithImage } from "@libry-content/common";
import { Publication } from "@libry-content/types";
import { useSitePalette } from "../../utils/useSitePalette";
import { CoverImage } from "./CoverImage";

interface Props {
  publication: Publication;
  resolution?: number;
  imageWidth?: ResponsiveValue<string>;
}

const transformStyles = {
  transition: ".9s",
  _groupHover: { transition: ".5s", transform: "scale(1.05)" },
};

const CoverImageWithPlaceholder = ({ publication, resolution, imageWidth, ...boxProps }: Props & BoxProps) => {
  const palette = useSitePalette();

  return (
    <Box {...boxProps}>
      {isPublicationWithImage(publication) ? (
        <CoverImage
          borderRadius=".5rem"
          publication={publication}
          resolution={resolution ?? 250}
          maxWidth={imageWidth}
          {...transformStyles}
        />
      ) : (
        <Grid
          css="aspect-ratio: .7;"
          borderRadius=".5rem"
          placeItems="center"
          width={imageWidth}
          aria-hidden
          {...palette.colors.darkaccent4.css}
          {...transformStyles}
        >
          <BookIcon fontSize="2em" />
        </Grid>
      )}
    </Box>
  );
};

export default CoverImageWithPlaceholder;
