import { Box, Grid, GridProps, Heading, Stack, Text } from "@biblioteksentralen/react";
import { formatNameInformal } from "@libry-content/common";
import { usePublicationLangAttribute } from "../../utils/hooks/usePublicationLangAttribute";
import { useSitePalette } from "../../utils/useSitePalette";
import { Edit } from "../editInSanity/EditInSanity";
import CoverImageWithPlaceholder from "../lists/CoverImageWithPlaceholder";
import TargetAudiences from "../lists/TargetAudiences";
import { ResolvedRecommendation, ResolvedRecommendationSummary } from "./sanityQuery";

interface Props extends GridProps {
  recommendation: ResolvedRecommendationSummary | ResolvedRecommendation;
  imageWidth?: string;
  headingSize?: "h1" | "h2";
  headingId?: string;
}

export const RecommendationHero = ({
  recommendation,
  imageWidth = "13rem",
  headingSize = "h2",
  children,
  headingId,
  ...gridProps
}: Props) => {
  const publicationLangAttribute = usePublicationLangAttribute(recommendation.publication);
  const palette = useSitePalette();

  if (!recommendation.publication) return null;
  const [title] = recommendation.publication.title?.split(":") ?? [];

  return (
    <Grid
      gridTemplateAreas={{
        base: ` "image header"
                "children children" `,
        sm: ` "image header"
              "image children"`,
      }}
      gridTemplateColumns={{ base: "8rem 1fr", sm: "max(30%, 8rem) 1fr", md: "auto 1fr" }}
      {...palette.colors.card.css}
      borderRadius={{ md: ".5rem" }}
      marginTop="2rem"
      padding={{ base: "1rem 1rem 2rem", sm: "2rem", lg: "2rem 3rem" }}
      gap={{ base: "1rem", sm: "1rem 2rem", md: "1rem 3rem" }}
      {...gridProps}
    >
      <CoverImageWithPlaceholder
        gridArea="image"
        marginTop={{ base: "-2rem", sm: "-2.5rem", md: "-3.5rem" }}
        publication={recommendation.publication}
        resolution={250}
        imageWidth={imageWidth}
      />
      <Stack gridArea="header" justifyContent={{ base: "center", md: "flex-end" }}>
        <TargetAudiences targetAudiences={recommendation.targetAudiences} />
        <Heading as={headingSize} size="lg" marginTop="0 !important" lang={publicationLangAttribute} id={headingId}>
          {title}
        </Heading>
        <Edit doc={recommendation} />
        <Text fontSize="sm" lang={publicationLangAttribute}>
          {recommendation.publication.author && formatNameInformal(recommendation.publication.author)}
        </Text>
      </Stack>
      <Box gridArea="children">{children}</Box>
    </Grid>
  );
};
