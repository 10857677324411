import { Box, BoxProps, Flex, Grid, GridProps } from "@biblioteksentralen/react";
import { coverImageAltText } from "../../utils/coverImageAltText";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";
import { PublicationSummary } from "./sanityQuery";
import { getRepresentativeManifestation } from "../../searchAndCollection/cordata/manifestations";
import { WorkCoverImage } from "../../searchAndCollection/components/WorkCoverImage";
import { WorkSearchResult } from "../../searchAndCollection/searchApi/searchWorks";

interface BookListFanProps {
  publications?: PublicationSummary[];
  maxWidth: string;
}

interface WorkFanProps {
  works?: WorkSearchResult[];
}

const nthChildStyles: BoxProps[] = [
  {
    maxWidth: "40%",
    transition: ".9s",
    _groupHover: { transition: ".5s", transform: "scale(1.075)" },
    zIndex: 3,
    order: 2,
  },
  {
    bottom: "1rem",
    right: "-15%",
    transition: "1.4s",
    _groupHover: { transition: "0.75s", transform: "scale(1.075) rotate(-5deg) translate(-.5em, -.3em)" },
    zIndex: 2,
    order: 1,
  },
  {
    bottom: "1rem",
    left: "-15%",
    transition: "1.7s",
    _groupHover: { transition: "0.95s", transform: "scale(1.075) rotate(5deg) translate(.5em, -.3em)" },
    zIndex: 1,
    order: 3,
  },
];

export function BookListCoversFan({ publications, maxWidth, ...chakraProps }: BookListFanProps & GridProps) {
  return (
    <Grid placeContent="center" isolation="isolate" {...chakraProps}>
      <Flex maxWidth={maxWidth} justifyContent="center" alignItems="flex-end">
        {publications?.map((publication, index) => (
          <SanityImagePreservingAspectRatio
            image={publication.image}
            alt={coverImageAltText(publication)}
            key={publication.image?.asset?._ref || index}
            maxWidth="30%"
            resolution={250}
            hideCaption
            {...nthChildStyles[index]}
          />
        ))}
      </Flex>
    </Grid>
  );
}

export function WorkCoversFan({ works, ...chakraProps }: WorkFanProps & GridProps) {
  return (
    <Grid height="11rem" placeItems="center" isolation="isolate" position="relative" {...chakraProps}>
      {works?.slice(0, 3).map((work, index) => {
        const representativeManifestation = getRepresentativeManifestation(work);
        if (!representativeManifestation) return null;

        return (
          <Box
            key={work.id}
            width="40%"
            borderRadius="lg"
            lineHeight={0}
            position="absolute"
            overflow="hidden"
            {...nthChildStylesWorks[index]}
          >
            <WorkCoverImage work={work} representativeManifestation={representativeManifestation} />
          </Box>
        );
      })}
    </Grid>
  );
}

const nthChildStylesWorks: BoxProps[] = [
  {
    bottom: "0",
    width: "55%",
    zIndex: 1,
  },
  {
    bottom: "0.5rem",
    right: "0%",
  },
  {
    bottom: "0.5rem",
    left: "0%",
  },
];
